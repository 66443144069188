/* font-family: 'Gruppo',
cursive;
font-family: 'Homemade Apple',
cursive;
font-family: 'Playfair Display',
serif;*/

body {
        margin: 0 auto;
        
}