:root {
        --background-color: rgb(18, 18, 18);
        --contrast-color: rgb(220, 220, 220);
}

.footer-container{
width: 100%;
height: 40px;
margin-top: 150px;
position: relative;
bottom: 0px;
text-align: center;
color: var(--orange);
}

@media (max-width: 600px) {
        .footer-container {
                        width: 100%;
                        height: 40px;
                        margin-top: 100px;
                        position: relative;
                        bottom: 0px;
                        text-align: center;
                        color: var(--orange);
                        font-size: 10px;
                }
}