/* font-family: 'Gruppo',
cursive;
font-family: 'Mitr',
sans-serif;
font-family: 'Montserrat',
sans-serif;
font-family: 'Poppins',
sans-serif;
font-family: 'Red Hat Text',
sans-serif; */

:root {
        --contrast-color: rgb(18, 18, 18);
        --background-color: rgb(253, 253, 255);
        --blue: rgb(102, 153, 184);
        --pink: rgb(189, 61, 78);
        --orange: rgb(216, 115, 57);
        --green: rgb(111, 181, 118);
        --yellow: rgb(243, 203, 45);

}
.App{
        margin: 0 auto;
        padding: 0px;
}